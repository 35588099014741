exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-index-jsx": () => import("./../../../src/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-pages-kontakt-index-jsx" */),
  "component---src-pages-o-nas-index-jsx": () => import("./../../../src/pages/o_nas/index.jsx" /* webpackChunkName: "component---src-pages-o-nas-index-jsx" */),
  "component---src-pages-uslugi-index-jsx": () => import("./../../../src/pages/uslugi/index.jsx" /* webpackChunkName: "component---src-pages-uslugi-index-jsx" */),
  "component---src-pages-uslugi-oczyszczanie-drewna-index-jsx": () => import("./../../../src/pages/uslugi/oczyszczanie_drewna/index.jsx" /* webpackChunkName: "component---src-pages-uslugi-oczyszczanie-drewna-index-jsx" */),
  "component---src-pages-uslugi-oczyszczanie-elewacji-index-jsx": () => import("./../../../src/pages/uslugi/oczyszczanie_elewacji/index.jsx" /* webpackChunkName: "component---src-pages-uslugi-oczyszczanie-elewacji-index-jsx" */),
  "component---src-pages-uslugi-oczyszczanie-metalu-index-jsx": () => import("./../../../src/pages/uslugi/oczyszczanie_metalu/index.jsx" /* webpackChunkName: "component---src-pages-uslugi-oczyszczanie-metalu-index-jsx" */)
}

